import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "maincontainer flex justify-center px-32 py-16" }
const _hoisted_2 = { class: "card shadow-2xl flex flex-row justify-center items-center min-h-360 w-96 p-8 w-full h-full" }
const _hoisted_3 = { class: "flex flex-column justify-center items-center w-full h-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createElementVNode("button", {
            class: "btn btnactive mr-8",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.redirectToApp('monitor')))
          }, "Monitor")
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("button", {
            class: "btn btnactive mr-8",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.redirectToApp('training')))
          }, "Training")
        ])
      ])
    ])
  ]))
}