
import { defineComponent } from 'vue';
import useVuelidate from '@vuelidate/core';
import { toast } from '../main';
import { mapGetters } from 'vuex';
import * as XLSX from 'xlsx';
import _ from 'lodash';
import { required, helpers, email } from '@vuelidate/validators';
import { add } from 'lodash';
import pagination from '@/components/pagination.vue';
import popup from '@/components/popup.vue';
const matchUrl = (value: any, vm: any) => value.substring(value.indexOf('@') + 1).split('.')[0] === vm.domainName;
export default defineComponent({
	data(): any {
		return {
			v$: useVuelidate(),
			orgUsersList: [],
			searchList: [],
			filter: {},
			tenantsList: [],
			showFilters: false,
			editUserObj: [],
			pop: false,
			editPopup: false,
			xPos: '',
			yPos: '',
			currentPage: '',
            pageSize: '',
            dynamicPagination:1,
			apps: [
				{
					appId: 10001,
					label: 'Monitor'
				},
				{
					appId: 10002,
					label: 'Training'
				}
			],
			activeValues: [
                {
                    id: 0,
                    value: 'Active'
                },
                {
                    id: 1,
                    value: 'Inactive'
                },
            ],
			roles: [
				{
					roleName: 'Monitor Root Admin',
					roleId: 10201,
					moduleId: 10001,
					disabled: true
				},
				{
					roleName: 'Admin',
					roleId: 10202,
					moduleId: 10001
				},
				{
					roleName: 'Department Owner',
					roleId: 10203,
					moduleId: 10001
				},
				{
					roleName: 'Task Owner',
					roleId: 10204,
					moduleId: 10001
				},
				{
					roleName: 'User',
					roleId: 10205,
					moduleId: 10001
				},
				// {
				// 	roleName: 'Training Root Admin',
				// 	roleId: 10206,
				// 	moduleId: 10002,
				// 	disabled: true
				// },
				{
					roleName: 'Admin',
					roleId: 10207,
					moduleId: 10002
				},
				{
					roleName: 'User',
					roleId: 10208,
					moduleId: 10002
				}
			],
			trainingRoles: [
				{
					roleName: 'Training Root Admin',
					roleId: 10206,
					moduleId: 10002,
					disabled: true
				},
				{
					roleName: 'Client Admin',
					roleId: 10207,
					moduleId: 10002
				},
				{
					roleName: 'User',
					roleId: 10208,
					moduleId: 10002
				}
			],
			classSort: '',
			className: '',
			dummyList: [],
			newImportModal: false,
			currentSort: 'name',
			currentSortDir: 'asc',
			departmentsList: [],
			showUserModal: false,
			hidepath: false,
			selectedUser: {},
			updatedUsersArray: [],
			mandatoryKeys: ['name', 'email', 'departmentName', 'jobTitle'],
			fullKeys: ['name', 'email', 'departmentName', 'jobTitle'],
			defaultUser: {
				uiroles: [10205, 10208],
				isActive: false
			},
			selected: '',
			filterDummy: {
				isActive:''
			}
		};
	},
	components:{
		pagination,
		popup
	},
	watch: {
		filterDummy: {
			handler(newVal: any) {
				this.filterDummy = newVal;
				this.filteredFunction();
			},
			deep: true
		}
    },
	validations() {
		return {
			selectedUser: {
				name: { required },
				email: { required, email, matchUrl },
				departmentId: { required },
				jobTitle: { required }
			}
		};
	},
	computed: {
		...mapGetters({ userInfo: 'userInfo' }),
		disableStatus: function (): any {
			let output;
			if (this.editPopup) {
				let objCopy = Object.assign({}, this.selectedUser);
                    if (JSON.stringify(objCopy) == JSON.stringify(this.editUserObj)) {
                        output = false;
                    } else {
                        output = true;
                    }
			} else {
				let val: any = [];
				Object.keys(this.selectedUser).forEach((value: any) => {
					val.push(value);
				});
				for (let key of val) {
					if (key == 'name' || key == 'email' || key == 'departmentId' || key == 'jobTitle') {
						if (this.selectedUser[key] != '') {
							output = true;
						}
					}
				}
			}
			return output;
		},
		sortedData: function (): any {
			return [...this.orgUsersList]
				.sort((a, b) => {
					let modifier = 1;
					if (this.currentSortDir === 'desc') modifier = -1;
					if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
					if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
					return 0;
				})
				.filter((row, index) => {
					this.dynamicPagination=index+1
					let start = (this.currentPage - 1) * this.pageSize;
					let end = this.currentPage * this.pageSize;
					if (index >= start && index < end) return true;
				});
		},
		cantGoBack(): any {
			return this.currentPage === 1;
		},
		cantGoForward(): any {
			return this.orgUsersList.length / this.pageSize <= this.currentPage;
		}
	},
	async created() {
		await this.getallTeamMembers();
		await this.getallTeams();
		await this.getAllTenants();
	},

	methods: {
		// someCondition: function () {
		// 	return this.selectedUser && this.selectedUser.name && this.selectedUser.email && this.selectedUser.departmentId && this.selectedUser.jobTitle;
		// },
		paginationUpdations(currentPage:any,pageSize:any){
            this.currentPage=currentPage;
            this.pageSize=pageSize;
        },
		cancelBtn(selectedUser: any) {
			this.v$.$reset();
			if (selectedUser._id) {
				this.selectedUser = { ...this.editUserObj };
			} else {
				Object.keys(selectedUser).forEach((prop) => {
					if(prop == '_id'){
						delete selectedUser[prop]
					}else{
						Array.isArray(selectedUser[prop]) ? (selectedUser[prop] = []) : (selectedUser[prop] = '');
					}
				});
			}
		},
		filterOpen() {
			this.showFilters = !this.showFilters;
			// this.filterDummy = {};
			for (const key in this.filterDummy) {
                    this.filterDummy[key] = '';
                }
			// Object.keys(this.filterDummy).forEach((prop) => {
            //     Array.isArray(this.filterDummy[prop]) ? (this.filterDummy[prop] = []) : (this.filterDummy[prop] = '');
            // });
		},
		// toolTip(type: any, e: any) {
		// 	if (type == 'text') {
		// 		if (e.offsetX > 1 && e.offsetX < e.target.offsetWidth && e.offsetY > 1 && e.offsetY < e.target.offsetHeight) {
		// 			this.pop = true;
		// 			this.xPos = e.offsetX + 38 + 'px';
		// 			this.yPos = -15 + 'px';
		// 		}
		// 	}
		// 	if (type == 'button') {
		// 		if (e.offsetX < e.target.offsetWidth && e.offsetY < e.target.offsetHeight) {
		// 			this.pop = true;
		// 			this.xPos = e.offsetX + e.target.offsetLeft + 13 + 'px';
		// 			this.yPos = -15 + 'px';
		// 		}
		// 	}
		// },
		toolTip(status: any, columnValue: any, index: any, e: any,name:any) {
            if (name=='button') {
                this.pop = true;
                this.presentTip = columnValue;
                this.tipIndex = index;
                this.columnName = name;
                this.xPos = e.offsetX + 63 + 'px';
                this.yPos = -18 + 'px';
            } else{
                this.handleTextTooltip(e, columnValue, index,name);
            }
        },
        handleTextTooltip(e:any, columnValue: any, index: any,name:any) {
            const container = e.currentTarget.closest('.table-ellipsis');
            if (container && this.isTextOverflowed(container)) {
                this.pop = true;
                this.presentTip = columnValue;
                this.tipIndex = index;
                this.columnName = name;
                this.xPos = e.offsetX + 38 + 'px';
                this.yPos = -15 + 'px';
            } else {
                this.pop = false; 
            }
        },
		isTextOverflowed(element:any) {
            return element.scrollWidth > element.clientWidth;
        },
		async getAllTenants() {
			await this.$http.get(`${process.env.VUE_APP_AUTH_API_URL}/tenants/getAll`).then((res: any) => {
				this.tenantsList = res.data;
			});
		},
		generateRoles: function (appId: any): any {
			const role = this.userInfo.roles.filter((role: any) => {
				return role.appId == appId;
			})[0].roleId;
			if (appId == 10001) {
				return role == 10202 || role == 10203 ? false : true;
			} else {
				return role == 10207 ? false : true;
			}
		},
		sort: function (s: any) {
			this.className = s;
			if (s === this.currentSort) {
				this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
			}
			this.currentSort = s;
			this.classSort = this.currentSortDir === 'asc' ? 'sort_asc' : 'sort_desc';
		},
		nextPage: function () {
			if (this.currentPage * this.pageSize < this.orgUsersList.length) this.currentPage++;
		},
		prevPage: function () {
			if (this.currentPage > 1) this.currentPage--;
		},

		filteredFunction() {
			let filteredPairs: any = {};
			for (const key in this.filterDummy) {
				const value = this.filterDummy[key];
				if (value !== '') {
					filteredPairs[key] = value;
				}
			}
			let propertyArray = Object.keys(filteredPairs);
			this.orgUsersList = this.searchList.filter((item: any) => {
				for (let key of propertyArray) {
					let filterColumn = this.filterDummy[key];
					let columnName = key;
					let hasMatch = false;
					for (var property in item) {
						let d = item;
						let c = 'departmentId' in d;
						if (!c && filterColumn == '') {
							return true;
						}
						let a: any;
						if (property == columnName) {
							if (property == 'departmentId') {
								this.departmentsList.filter((department: any) => {
									if (department._id == item[property]) {
										a = department.teamName;
									}
								});

								if (a.toLowerCase().includes(filterColumn.toLowerCase())) hasMatch = true;
							} else if (property == 'isActive') {
								if (item[property].toString().toLowerCase() == 'true') {
									let a = 0;
									if (a == filterColumn) hasMatch = true;
								}
								if (item[property].toString().toLowerCase() == 'false') {
									let a = 1;
									if (a == filterColumn) hasMatch = true;
								}
							} else if (property == 'roles') {
								this.trainingRoles.filter((role: any) => {
									if (role.roleId == item[property]) {
										a = role.roleName;
									}
								});

								if (a.toLowerCase().includes(filterColumn.toLowerCase())) hasMatch = true;
							} else {
								if (item[property].toLowerCase().includes(filterColumn.toLowerCase())) hasMatch = true;
							}
						}
						if (hasMatch) {
							break;
						}
					}

					if (!hasMatch) return false;
				}
				return true;
			});
		},
		async getallTeamMembers() {
			await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
				res.data.forEach((user: any) => {
					if (user.isActive == true) {
						user.roles = user.roles[1].roleId;
						user.isActive = true;
					} else {
						user.roles = user.roles[1].roleId;
						user.isActive = false;
					}
				});
				this.orgUsersList = res.data;
				this.searchList = res.data;

				this.dummyList = res.data;
			});
		},
		async getallTeams() {
			await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/departments/get`).then((res: any) => {
				this.departmentsList = res.data;
			});
		},
		async addOrUpdateUser(selectedUser: any) {
			let finalUsers = this.searchList.filter((team: any) => team._id);
			let userExists = finalUsers.some((el: any) => {
				return el.name.toLowerCase() === selectedUser.name.toLowerCase();
			});
			this.v$.$touch();
			if (!this.v$.$invalid) {
				let tempRoles = [
					{
						appId: 10001,
						roleId: 10205
					},
					{
						appId: 10002,
						roleId: selectedUser.uiroles
					}
				];

				selectedUser.roles = tempRoles;
				let deletedKeys=_.omit(selectedUser, ['domainName', 'uiroles'])
				if (!selectedUser._id && !userExists) {
					await this.$http.post(`${process.env.VUE_APP_ORG_API_URL}/users/add`, [deletedKeys]).then(() => {
					
						this.showUserModal = false;
						this.getallTeamMembers();
						toast.info('saved', {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
						// this.sendInvite(selectedUser);
					});
				} else {
					this.updateUser(selectedUser);
				}
			} else {
				// this.v$.$reset();
				if (selectedUser._id) {
						toast.error(`Cannot Update`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
					} else{
						toast.error(`Cannot save`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				}
			}
		},
		showPopup(user: any, action: string) {
			this.v$.$reset();
			this.defaultUser.name = '';
			this.defaultUser.email = '';
			this.defaultUser.departmentId = '';
			this.defaultUser.isActive = false;
			this.defaultUser.jobTitle = '';
			this.defaultUser.uiroles = 10208;
			this.selectedUser = action == 'add' ? this.defaultUser : {...user};
			let tenantdata = this.tenantsList.filter((tenant: any) => {
				return tenant._id == this.userInfo.organisationId;
			});
			this.selectedUser.domainName = tenantdata[0].tenantName;
			if (action != 'add') {
				this.editPopup = true;
				this.selectedUser.uiroles = this.selectedUser.roles;
				this.editUserObj = { ...this.selectedUser }
			}
			this.showUserModal = !this.showUserModal;
		},
		showEditPopup(user: any) {
			this.selectedUser = {...user};
			this.showUserModal = !this.showUserModal;
		},
		async updateUser(selectedUser: any) {
			if (selectedUser._id) {
				await this.$http.post(`${process.env.VUE_APP_ORG_API_URL}/users/update`, selectedUser).then(() => {
					this.showUserModal = false;
					toast.info('Updated', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
					this.getallTeamMembers();
					this.getallTeams();
				});
			}
		},

		toggleImportPopup() {
			// this.newImportModal = !this.newImportModal;
			this.$refs.file.click();
		},

		async fileReader(oEvent: any) {
			var oFile = oEvent.target.files[0];
			var reader = new FileReader();
			reader.onload = async (e: any) => {
				var data = e.target.result;
				data = new Uint8Array(data);
				var workbook = XLSX.read(data, { type: 'array' });
				/* DO SOMETHING WITH workbook HERE */
				var first_sheet_name = workbook.SheetNames[0];
				/* Get worksheet */
				var worksheet = workbook.Sheets[first_sheet_name];
				var jsonObj: any = XLSX.utils.sheet_to_json(worksheet, {
					raw: true,
					header: ['name', 'email', 'departmentName', 'jobTitle'],
					range: 1
				});
				let total = jsonObj.length;
				let valid = 0;
				var excelFinalDepartments: any = [];
				var departments: any = [];
				var finalarray: any = [];
				jsonObj.forEach((department: any) => {
					if (department.departmentName != '' && department.departmentName != undefined) {
						excelFinalDepartments.push(department.departmentName.toLowerCase());
					}
				});
				this.departmentsList.forEach((team: any) => {
					departments.push(team.teamName.toLowerCase());
				});
				let excelDepartments = [...new Set(excelFinalDepartments)];
				let finaldepartments = [...new Set(departments)];

				var differenceDepartments = excelDepartments.filter((x) => !finaldepartments.includes(x));
				if (differenceDepartments.length > 0) {
					var payload: any = [];
					differenceDepartments.forEach(async (singleteam: any) => {
						payload.push({
							teamName: singleteam,
							teamOwner: 'N/A'
						});
					});
					await this.$http.post(`${process.env.VUE_APP_ORG_API_URL}/departments/create`, payload).then(async () => {
						await this.getallTeams();
					});
				}
				for (var i = 0; i < jsonObj.length; i++) {
					for (var j = 0; j < this.departmentsList.length; j++) {
						if (jsonObj[i].name != undefined && jsonObj[i].departmentName != undefined && jsonObj[i].email != undefined) {
							var excelTeamName: any = jsonObj[i].departmentName;
							var teamListTeamname: any = this.departmentsList[j].teamName;
							if (excelTeamName.toLowerCase() == teamListTeamname.toLowerCase()) {
								var updatedObject = Object.assign(jsonObj[i], {
									departmentId: this.departmentsList[j]._id,
									roles: [
										{ appId: 10001, roleId: 10205 },
										{ appId: 10002, roleId: 10208 }
									],
									isActive: false
								});
								finalarray.push(updatedObject);
							}
						}
					}
				}

				this.orgUsersList;
				let users = finalarray.filter((o1: any) => !this.orgUsersList.some((o2: any) => o1.name.toLowerCase() === o2.name.toLowerCase()));
				let tenantdata = this.tenantsList.filter((tenant: any) => {
					return tenant._id == this.userInfo.organisationId;
				});
				let domainName = tenantdata[0].tenantName;
				let updatedUsers: any = [];
				for (var k = 0; k < users.length; k++) {
					if (users[k].email.substring(users[k].email.indexOf('@') + 1).split('.')[0] === domainName) {
						updatedUsers.push(users[k]);
					}
				}
				valid = updatedUsers.length;
				if (updatedUsers.length > 0) {
					await this.$http.post(`${process.env.VUE_APP_ORG_API_URL}/users/add`, updatedUsers).then(() => {
						toast.info(`Imported ${total} rows, Valid rows: ${valid}, Ignored rows: ${total - valid}`, {
							timeout: 3000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
						this.getallTeamMembers();
						this.newImportModal = !this.newImportModal;
					});
				} else {
					toast.error(`cannot save with same name`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				}
			};
			reader.readAsArrayBuffer(oFile);
		}
	}
});
