
import { defineComponent } from 'vue';
import { emitter } from '../main';
import { mapGetters } from 'vuex';
export default defineComponent({
	data():any {
		return {
			isSidebarToggle: true,
			appsVisible:false,
			menuVisible: false,
			selectedOptionName: '',
			passingRouteName: '',
			a:0,
				menus: [
				{
					optionName: 'teams',
					optionRoute: 'teams',
					defaultoptionImage: require('@/assets/teamsDefault.svg'),
					colouredoptionImage: require('@/assets/teamsColoured.svg'),
					displayOptionName: 'Teams'
				},
				{
					optionName: 'users',
					defaultoptionImage: require('@/assets/usersDefault.svg'),
					colouredoptionImage: require('@/assets/usersColoured.svg'),
					optionRoute: 'users',
					displayOptionName: 'Users',
				}]
		};
	},
		
	methods:{
		navigate(name:any, routerOption:any) {
			this.$store.dispatch('selectedToggle',this.isSidebarToggle);
			this.$store.dispatch('selectedRoute', name);
			this.$router.push({ name: routerOption });
		},
		toggleSideMenuBar() {
			this.isSidebarToggle = !this.isSidebarToggle;
			emitter.emit('toggle-sidebar', this.isSidebarToggle);
		},
		triggerNav(app:string) {
			if(app=="workflow"){
				window.location.href = `${process.env.VUE_APP_AUTH_WEBAPP_URL}/session?returnTo=${process.env.VUE_APP_WORKFLOW_WEBAPP_URL}`;
			}else{
				window.location.href = `${process.env.VUE_APP_AUTH_WEBAPP_URL}/session?returnTo=${app == 'monitor' ? process.env.VUE_APP_MONITOR_WEBAPP_URL : process.env.VUE_APP_TRAINING_WEBAPP_URL}`;
			}
			
		},
	},
	computed: {
		currentRouteName() {
            return this.$route.name;
        },
		...mapGetters({ userInfo: 'userInfo',toggleState:'toggle'})
	},
	mounted() {
		if(this.$route.path.includes('teams')){
            this.passingRouteName = 'teams';
        }else if(this.$route.path.includes('users')){
            this.passingRouteName = 'users'
        }
        console.log(this.passingRouteName, 'this.passingRouteName');
        this.selectedOptionName = this.passingRouteName;
        console.log('selectedOptionName', this.selectedOptionName);
			emitter.on('apps-sidemenubar', (isMenuToggle) => {
			this.menuVisible = isMenuToggle;
		});
		emitter.on('apps', (isToggle: any) => {
			this.menuVisible = isToggle;
		});
		this.isSidebarToggle=this.toggleState;
		emitter.on('toggle-sidebar', (isToggle: any) => {
		this.isSidebarToggle = isToggle;
		});
	}
});
