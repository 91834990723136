
import { defineComponent } from 'vue';
export default defineComponent({
	methods: {
		redirectToApp(app:string) {
			if(app == 'monitor')
				window.location.href = `${process.env.VUE_APP_AUTH_WEBAPP_URL}/session?returnTo=${process.env.VUE_APP_MONITOR_WEBAPP_URL}`;
			else
				window.location.href = `${process.env.VUE_APP_AUTH_WEBAPP_URL}/session?returnTo=${process.env.VUE_APP_TRAINING_WEBAPP_URL}`;
		}
	}
});

