import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";

import defaultLayout from "@/layouts/defaultLayout.vue";
import emptyLayout from "@/layouts/emptyLayout.vue";
import adminlayout from "@/layouts/adminLayout.vue";
import teams from "@/features/teams.vue";
import usermanagment from "@/features/userManagment.vue";
import apps from "@/features/apps.vue";
import onboarding from "@/features/onboarding.vue";
import Default from "@/features/default.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/teams",
        name: "teams",
        meta: {
            title: "Teams",
            layout: defaultLayout,
        },
        component: teams,
        beforeEnter:(to,from,next)=>{
            const userInfo = store.getters.userInfo;
            const userRoles = userInfo.roles.map((a:any) => a.roleId);
            if(userRoles.includes(10201) || userRoles.includes(10202) || userRoles.includes(10207)){
                next()
            } else{
                next('/apps')
            }
        }
    },
    {
        path: "/users",
        name: "users",
        meta: {
            title: "Users",
            layout: defaultLayout,
        },
        component: usermanagment,
        beforeEnter:(to,from,next)=>{
            const userInfo = store.getters.userInfo;
            const userRoles = userInfo.roles.map((a:any) => a.roleId);
            if(userRoles.includes(10201) || userRoles.includes(10202) || userRoles.includes(10207)){
                next()
            } else{
                next('/apps')
            }
        }
    },
    {
        path: "/apps",
        name: "apps",
        meta: {
            title: "Apps",
            layout: defaultLayout,
        },
        component: apps,
    },
    {
        path: "/",
        name: "default",
        meta: {
            layout: defaultLayout,
        },
        component: Default
    },
    {
    path: '/error',
    name: 'error',
    meta: {
        title: 'error',
        layout: 'empty-layout',
    },
    props:true,
    component: () => import('@/components/error.vue')
    },
    {
    path: '/:pathMatch(.*)*',
    redirect: '/error',
    },
]


const router = createRouter({
    history: createWebHistory('/'),
    routes,
});

router.beforeEach(async(to, from, next) => {
    document.title=`Organisation - ${to.meta.title}`
    store.state.isLoading = true;
    console.log('Router check triggered')
    if(to.name == 'default'){
        if (to.query.session) {
            console.log('Nav to default route with',to.query.session)
            localStorage.setItem('AUTH_ID', to.query.session.toString());
            await store.dispatch('getUserInfo').then((res) => {
                console.log('response',res.data[0]);
                if (!res.data[0])   window.location.href = `${process.env.VUE_APP_AUTH_WEBAPP_URL}/session?returnTo=${process.env.VUE_APP_ORG_WEBAPP_URL}`;
                else {
                    next('/users');
                }
            })
        }
        normalAuthenticationCheck(next,'/users');
    } else{
        normalAuthenticationCheck(next);
    }
});
router.afterEach(async (to, from, next) => {
    store.state.isLoading = false
});


async function normalAuthenticationCheck(next:any,nextpath?:string){
    console.log('Inside normal check')
    if (!localStorage.getItem('AUTH_ID')) {
        console.log('AUTH ID NOT found')
         window.location.href = `${process.env.VUE_APP_AUTH_WEBAPP_URL}/session?returnTo=${process.env.VUE_APP_ORG_WEBAPP_URL}`;
    } else{
        const userInfo = store.getters.userInfo;
        console.log('USER INFO',userInfo)
        if(!userInfo.roles){
            await store.dispatch('getUserInfo').then((res) =>{
                next(nextpath);
            });
        }
        else{
            next();
        }
    }
}


export default router;

