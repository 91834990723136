
import navbar from '@/components/navbar.vue';
import sidemenubar from '@/components/sidemenubar.vue';
import Footer from '@/components/footer.vue'
import { defineComponent } from 'vue';
import { emitter } from '../main';
import { mapGetters } from 'vuex';
export default defineComponent({
	components: {
		navbar,
		sidemenubar,Footer
	},
	data() {
		return {
			isToggling: true,
			appsVisible: false,
		};
	},
	computed: {
		...mapGetters({selectedOptionName:'val', toggleState:'toggle'})
	},
	methods:{
		closeApps(){
			this.appsVisible=false;
			emitter.emit('apps', this.appsVisible);
			},
		 },
	mounted() {
		this.isToggling = this.toggleState;
		emitter.on('toggle-sidebar', (isToggle: any) => {
			this.isToggling = isToggle;
		});
	}
});
