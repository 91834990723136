
import { defineComponent } from 'vue';
import { emitter } from '@/main';

export default defineComponent({
    props: ['dynamicPagination','sortedData','entityId'],
    data(): any {
        return {
            pageBoxes:3,
            pageIndexStart: [],
            currentPage: 1,
            leftBox: 'left',
            rightBox: 'right',
            pageIndexEnd: [],
            totalPages:'',
            pageSize: 20,
            pagesArray:[],
            start:'',
            end:'',
            display:true,
            // dynamicPagination:'',
        };
	},
	watch: {
		dynamicPagination: {
			handler(newVal, oldVal) {
				console.log('newVal', newVal);
				if (newVal !== oldVal) {
					this.getPagination();
				}
			},
			deep: true
		},
		sortedData: {
			handler() {
				this.sortedData.length == 0 ? (this.display = false) : (this.display = true);
			},
			deep: true
		}
	},
	created() {
		this.getPagination();
	},
	computed: {
		cantGoBack(): any {
			this.$emit('currentPageUpdate', this.currentPage, this.pageSize);
			return this.currentPage === 1;
		},
		cantGoForward(): any {
			return this.currentPage == this.totalPages;
		}
	},

	methods: {
		getPagination() {
			if (this.entityId == 20010) {
				this.pageSize = 4;
			}
			this.totalPages = Math.ceil(this.dynamicPagination / this.pageSize);
			this.pagesArray = Array.from({ length: this.totalPages }, (_, index) => index + 1);
			if (this.dynamicPagination !== 0) {
				if (this.totalPages > 7) {
					this.pageIndexEnd = [this.totalPages - 2, this.totalPages - 1, this.totalPages];
				}
				this.pageStart(0, 1);
			}
		},
		checkForSamePageNo(obj: any, state: any) {
			if (state == 'left') {
				let x = obj.some((num: any) => {
					return this.pageIndexEnd.includes(num);
				});
				return !x;
			} else if (state == 'right') {
				let x = obj.some((num: any) => {
					return this.pageIndexStart.includes(num);
				});
				return !x;
			}
		},
		backAndForth(index: any, num: any,status:any) {
			this.currentPage == this.pagesArray[0] ? (this.start = 0) : '';
			this.currentPage == this.pagesArray[this.pagesArray.length - 1] ? (this.start = num - this.pageBoxes) : '';
			let position = Math.floor(this.pageBoxes / 2);
            let array=[];
            status=='start'? (array = this.pageIndexStart):( array = this.pageIndexEnd);
                if (array.indexOf(num) == 0 && this.currentPage != this.pagesArray[0]) {
                    console.log('start');
                    this.start = this.pagesArray.indexOf(num - position);
                } else if (this.currentPage != this.pagesArray[this.pagesArray.length - 1] && array.indexOf(num) == this.pageBoxes - 1) {
                    this.start = this.pagesArray.indexOf(num - position);
                } else if (array.indexOf(num) == position) {
                    this.start = this.pagesArray.indexOf(num - 1);
                } else if(this.start ===''){
                    return array;
                }
			this.end = this.start + this.pageBoxes - 1;
			let modifiedArray = this.pagesArray.slice(this.start, this.end + 1);
            return modifiedArray;
		},
		pageStart(index: any, num: any) {
			this.start = '';
			this.end = '';
			this.currentPage = num;
			if (this.totalPages <= 7) {
				if (this.pagesArray.length > this.pageBoxes) {
                    this.pageIndexStart = this.backAndForth(index,num,'start')
				} else {
					this.pageIndexStart = this.pagesArray;
				}
			} else {
				let mod = this.backAndForth(index,num,'start')
				if (this.checkForSamePageNo(mod, 'left')) {
					this.pageIndexStart = mod;
				}
				this.leftBox = 'left';
				this.rightBox = '';
			}
		},
		pageEnd(index: any, num: any) {
			this.start = '';
			this.end = '';
			this.currentPage = num;
			let mod = this.backAndForth(index,num,'end');
			if (this.checkForSamePageNo(mod, 'right')) {
				this.pageIndexEnd = mod;
			}
			this.leftBox = '';
			this.rightBox = 'right';
		},
		nextPage: function () {
			if (this.totalPages > 7) {
				if (this.leftBox == 'left' && this.currentPage != this.pageIndexEnd[0]) {
					this.pageStart('next', this.currentPage + 1);
				}
				if (this.leftBox == 'left' && this.currentPage == this.pageIndexEnd[0]) {
					this.pageEnd('next', this.currentPage);
				} else if (this.rightBox == 'right') {
					this.pageEnd('next', this.currentPage + 1);
				}
			} else {
				this.pageStart('next', this.currentPage + 1);
			}
		},
		prevPage: function () {
			if (this.totalPages > 7) {
				if (this.rightBox == 'right' && this.currentPage != this.pageIndexStart[this.pageBoxes - 1]) {
					this.pageEnd('prev', this.currentPage - 1);
				}
				if (this.rightBox == 'right' && this.currentPage == this.pageIndexStart[this.pageBoxes - 1]) {
					this.pageStart('next', this.currentPage);
				} else if (this.leftBox == 'left') {
					this.pageStart('prev', this.currentPage - 1);
				}
			} else {
				this.pageStart('prev', this.currentPage - 1);
			}
		}
	}
});
