export const globalApiMixin={
    methods:{
        generateApiUrl() {
			if (window.location.hostname.includes('localhost')) {
				return 'http://localhost:3020/services/auth';
			} else {
				return window.location.origin + '/services/auth';
			}
		},
		
    }
}