
import { defineComponent } from 'vue';
import { emitter, toast } from '../main';
import { mapGetters } from 'vuex';
export default defineComponent({
	data(): any {
		return {
			isToggle: false,
			showBlock: false,
			displayName:'',
			isappsToggle:false,
			isMenuToggle: false,
		};
	},
	
	computed:{
		...mapGetters({ userInfo: 'userInfo' })
	},
	methods: {
		getUserName(){
			return this.userInfo.name.charAt(0).toUpperCase()
		},
		ontoggle() {
			this.isMenuToggle=!this.isMenuToggle
			this.showBlock=false;
			emitter.emit('apps-sidemenubar', this.isMenuToggle);
		},
		closeApps() {
			this.showBlock=false;
			this.isToggle = false;
			emitter.emit('apps-sidemenubar', this.isToggle);
		},
		async logout() {
			var payload = {
				headers: {
					Authorization: 'Bearer ' + localStorage.getItem('AUTH_ID')
				}
			};
			await this.$http
				.post(`${process.env.VUE_APP_AUTH_API_URL}/logout`, payload)
				.then((response: any) => {
					if (response.status == 200) {
						toast.success(`Logout Successfully`, {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
					}
				})
				.catch((error: any) => {
					toast.error(`Save failed with ${error}`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
			localStorage.removeItem('AUTH_ID');
			window.location.replace(`${process.env.VUE_APP_AUTH_WEBAPP_URL}/logout?returnTo=${process.env.VUE_APP_TRAINING_WEBAPP_URL}`);
		},

		triggerNav(app:string) {
			window.location.href = `${process.env.VUE_APP_AUTH_WEBAPP_URL}/session?returnTo=${app == 'monitor' ? process.env.VUE_APP_MONITOR_WEBAPP_URL : process.env.VUE_APP_TRAINING_WEBAPP_URL}`;
		},
			async getAllTenants() {
				await this.$http.get(`${process.env.VUE_APP_AUTH_API_URL}/tenants/getAll`).then((res: any) => {
				this.allTenants = res.data;
			this.allTenants.filter((tenant:any) => {
			let userDomain = this.userInfo.email.substring(this.userInfo.email.lastIndexOf("@") + 1);
            let  userOrgDetails = userDomain.split('.')[0];

			if( userOrgDetails.toLowerCase()==tenant.tenantName.toLowerCase()){
				this.displayName= tenant.displayName
			}
					
				});
			});
		}
	},

	mounted() {
		this.getAllTenants();
		emitter.on('apps', (isToggle: any) => {
			 this.showBlock = isToggle
			 this.isToggle = !this.isToggle
	   });
	}
})
