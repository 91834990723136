
import { defineComponent } from 'vue';
import _ from 'lodash';
// import { CheckIcon, XIcon, TrashIcon, PencilAltIcon} from '@heroicons/vue/outline';
import { globalApiMixin } from '../GlobalMixins/apiGlobalMixins';
import Multiselect from '@vueform/multiselect';
import { emitter, toast } from '../main';
import useVuelidate from '@vuelidate/core';
import { required, helpers, email } from '@vuelidate/validators';
import pagination from '@/components/pagination.vue';
import popup from '@/components/popup.vue';
export default defineComponent({
	mixins: [globalApiMixin],
	// components: { CheckIcon, XIcon, TrashIcon, PencilAltIcon, PlusSmIcon},
	data(): any {
		return {
			v$: useVuelidate(),
			visibility: false,
			currentPage: '',
            pageSize: '',
            dynamicPagination:1,
			dummyList: [],
			teamsList: [],
			showUserModal: false,
			dummyTeamObj: {},
			editPopup: false,
			teamObjPopup: {
				teamName: '',
				teamOwner: ''
			},
			currentSort: 'name',
			currentSortDir: 'asc',
			classSort: '',
			className: '',
			optionsUserArray: [],
			filterDummy: {}
		};
	},
	components: { Multiselect, pagination, popup },
	validations() {
		return {
			// teamsList: {
			// 	$each: helpers.forEach({
			// 		teamName: { required },
			// 		teamOwner: { required }
			// 	})
			// },
			teamObjPopup: {
				teamName: { required },
				teamOwner: { required }
			}
		};
	},
	watch: {
		filterDummy: {
			handler(newVal: any) {
				this.filterDummy = newVal;
				this.filteredFunction();
			},
			deep: true
		}
	},
	methods: {
		paginationUpdations(currentPage:any,pageSize:any){
            this.currentPage=currentPage;
            this.pageSize=pageSize;
        },
		filterOpen() {
			this.visibility = !this.visibility;
			this.filterDummy = {};
		},
		cancelBtn(teamObjPopup: any) {
			this.v$.$reset();
			if (teamObjPopup._id) {
				this.teamObjPopup = { ...this.dummyTeamObj };
			} else {
				Object.keys(this.teamObjPopup).forEach((prop) => {
						Array.isArray(teamObjPopup[prop]) ? (teamObjPopup[prop] = []) : (teamObjPopup[prop] = '');
				});
			}
		},
		filteredFunction() {
			let filteredPairs: any = {};
			for (const key in this.filterDummy) {
				const value = this.filterDummy[key];
				if (value !== '') {
					filteredPairs[key] = value;
				}
			}
			let propertyArray = Object.keys(filteredPairs);
			this.teamsList = this.dummyList.filter((item: any) => {
				for (let key of propertyArray) {
					let filterColumn = this.filterDummy[key];
					let columnName = key;
					let hasMatch = false;
					for (var property in item) {
						if (property == columnName) {
							if (columnName == 'teamOwner') {
								let a = this.extractDepartmentName(item.teamOwner);
								if (a.toLowerCase().includes(filterColumn.toLowerCase())) {
									hasMatch = true;
								}
							} else {
								if (item[property].toLowerCase().includes(filterColumn.toLowerCase())) hasMatch = true;
							}
						}
						if (hasMatch) {
							break;
						}
					}
					if (!hasMatch) return false;
				}
				return true;
			});
		},
		extractDepartmentName(name: any) {
			let userName = this.users.find((user: any) => {
				return user._id == name;
			});
			if (userName) {
				return userName.name;
			} else {
				return '';
			}
		},
		nextPage: function () {
			if (this.currentPage * this.pageSize < this.teamsList.length) this.currentPage++;
		},
		prevPage: function () {
			if (this.currentPage > 1) this.currentPage--;
		},
		addTeam(): any {
			this.editPopup = false;
			this.showUserModal = true;
			this.v$.$reset();
			Object.keys(this.teamObjPopup).forEach((prop) => {
				Array.isArray(this.teamObjPopup[prop]) ? (this.teamObjPopup[prop] = []) : (this.teamObjPopup[prop] = '');
			});
		},

		editTeam(team: any) {
			this.editPopup = true;
			this.showUserModal = !this.showUserModal;
			this.teamObjPopup = {...team};
			this.dummyTeamObj = { ...team };
		},
		clearAllFlags(index: any) {
			this.teamsList.splice(index, 1);
		},
		saveTeam(teamObjPopup: any) {
			this.v$.$touch();
			// let finalTeams = this.teamsList.slice(0, index);
			// let teamExists = finalTeams.some((el: any) => {
			// 	return el.teamName.toLowerCase() === this.teamsList[index].teamName.toLowerCase();
			// });
			// let errorCondition = this.v$.teamsList.$each.$message[index];
			// if (!teamExists && errorCondition.length == 0) {
				// this.v$.$reset();
			if (!this.v$.$invalid) {
				if (teamObjPopup._id) {
					this.$http.post(`${process.env.VUE_APP_ORG_API_URL}/departments/update/${teamObjPopup._id}`, _.omit(teamObjPopup, ['_id'])).then(() => {
						this.getAllTeams();
						toast.info(`Team Updated`, {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
					});
				} else {
					let teamObjArray = []
					if(Object.keys(teamObjPopup).includes('_id')){
						delete teamObjPopup._id;
					}
					teamObjArray.push(teamObjPopup)
					this.$http.post(`${process.env.VUE_APP_ORG_API_URL}/departments/create`,teamObjArray).then(() => {
						this.getAllTeams();
						toast.info(`Team Saved`, {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
					});
				}
				this.showUserModal = false;
				} else {
				// this.v$.$reset();
					if (teamObjPopup._id) {
						toast.error(`Cannot Update`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
					} else{
						toast.error(`Cannot save`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				}
				
			}
			// }
		},
		deleteTeam(index: any, team: any) {
			// if(team._id){
			this.$http.delete(`${process.env.VUE_APP_ORG_API_URL}/departments/delete/${this.teamsList[index]._id}`).then(() => {
				this.getAllTeams();
				toast.error(`Team Deleted`, {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			});
			// }
			// else{
			// 	team.splice(index, 1)
			// }
		},
		async getAllUsers() {
			await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
				this.users = res.data;
			});
			for (var i = 0; i < this.users.length; i++) {
				// if(this.users[i].isActive==true){
					this.optionsUserArray.push({ label: this.users[i].name, value: this.users[i]._id });
				// }
			}
		},
		async getAllTeams() {
			await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/departments/get`).then((res: any) => {
				this.teamsList = res.data;
				this.dummyList = res.data;
			});
		},
		sort: function (s: any) {
			this.className = s;
			if (s === this.currentSort) {
				this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
			}
			this.currentSort = s;
			this.classSort = this.currentSortDir === 'asc' ? 'sort_asc' : 'sort_desc';
		},
		toolTip(status: any, columnValue: any, index: any, e: any,name:any) {
            if (name=='button') {
                this.pop = true;
                this.presentTip = columnValue;
                this.tipIndex = index;
                this.columnName = name;
                this.xPos = e.offsetX + 48 + 'px';
                this.yPos = -20 + 'px';
            } else{
                this.handleTextTooltip(e, columnValue, index,name);
            }
        },
        handleTextTooltip(e:any, columnValue: any, index: any,name:any) {
            const container = e.currentTarget.closest('.table-ellipsis');
            if (container && this.isTextOverflowed(container)) {
                this.pop = true;
                this.presentTip = columnValue;
                this.tipIndex = index;
                this.columnName = name;
                this.xPos = e.offsetX + 38 + 'px';
                this.yPos = -15 + 'px';
            } else {
                this.pop = false; 
            }
        },
		isTextOverflowed(element:any) {
            return element.scrollWidth > element.clientWidth;
        },
	},
	computed: {
		disableStatus: function (): any {
			let output;
			if (this.editPopup) {
				let objCopy = Object.assign({}, this.teamObjPopup);
				if (JSON.stringify(objCopy) == JSON.stringify(this.dummyTeamObj)) {
					output = false;
				} else {
					output = true;
				}
			} else {
				let val: any = [];
				Object.values(this.teamObjPopup).forEach((value: any) => {
					val.push(value);
				});
				for (let n of val) {
					if (n != '') {
						output = true;
					}
				}
			}
			return output;
		},
		sortedData: function () {
			return [...this.teamsList]
				.sort((a, b) => {
					let modifier = 1;
					if (this.currentSortDir === 'desc') modifier = -1;
					if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
					if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
					return 0;
				})
				.filter((row, index) => {
					this.dynamicPagination=index+1
					let start = (this.currentPage - 1) * this.pageSize;
					let end = this.currentPage * this.pageSize;
					if (index >= start && index < end) return true;
				});
		},
		cantGoBack() {
			return this.currentPage === 1;
		},
		cantGoForward() {
			return this.teamsList.length / this.pageSize <= this.currentPage;
		}
	},
	async mounted() {
		await this.getAllUsers();
		await this.getAllTeams();
	}
});
