
import emptylayout from '@/layouts/emptyLayout.vue';
import spinner from '@/components/spinner.vue';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
export default defineComponent({
	components: {
		spinner
	},
	computed: {
		layout() {
			return this.$route.meta.layout || emptylayout;
		},
		...mapGetters({ loading: 'loading' })
	}
});
